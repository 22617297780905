import React, { useEffect, useContext } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import styled from "styled-components";
import { Link, graphql, navigate } from "gatsby";
import Helmet from "react-helmet";
import Media from "react-media";

// Context
import WindowWidthContext from "../../../components/context/window-width";
import StoreContext from "../../../components/context/store";
import { FooterColor } from "../../../components/context/footer-color";

// Components
import Layout from "../../../components/account/Layout";
import { AccountNavigation } from "../../../components/account/account-navigation";
import { MobileAccountNavigation } from "../../../components/account/mobile-account-navigation";
import { AspectRatioImageContainer } from "../../../components/containers/aspect-ratio-image-container";

// Icons
import {
	TradeImages,
	TradeTechSpecs,
	TradeCAD,
} from "../../../components/icons/trade-icons";

// Styles
import {
	secondaryDark,
	secondaryLight,
	tertiary,
} from "../../../components/utils/colors";

import {
	Page,
	AccountContainer,
	ContentContainer,
} from "../../../components/trade/trade-components";

const SingleProduct = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-gap: 30px;
	align-items: flex-start;

	@media (max-width: 860px) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		grid-template-columns: unset;
		grid-gap: unset;
	}

	padding: 30px 0;
	border-bottom: 1px solid ${secondaryDark};

	&:first-of-type {
		padding: 0 0 30px 0;
	}

	&:last-of-type {
		border-bottom: 0;
	}

	& .image-container {
		grid-column-start: 1;
		grid-column-end: 4;

		@media (max-width: 860px) {
			flex: 50%;
			max-width: 50%;
		}

		@media (max-width: 700px) {
			flex: 100%;
			max-width: 100%;
		}
	}

	& .title-downloads-container {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-gap: 30px;
		align-items: flex-start;

		grid-column-start: 4;
		grid-column-end: 13;

		@media (max-width: 860px) {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			grid-template-columns: unset;
			grid-gap: unset;

			flex: 50%;
			max-width: 50%;

			padding: 0 0 0 30px;
		}

		@media (max-width: 700px) {
			flex: 100%;
			max-width: 100%;

			padding: 30px 0 0 0;
		}
	}

	& .title-container {
		grid-column-start: 1;
		grid-column-end: 7;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: space-between;

		@media (max-width: 860px) {
			padding: 0 0 30px 0;
		}

		& .product-text p {
			overflow: hidden;
			-webkit-box-orient: vertical;
			display: block;
			display: -webkit-box;
			overflow: hidden !important;
			text-overflow: ellipsis;
			-webkit-line-clamp: 3;

			margin: 0;

			&:nth-of-type(2),
			&:nth-of-type(3),
			&:nth-of-type(4),
			&:nth-of-type(5),
			&:nth-of-type(6) {
				display: none;
			}
		}
	}

	& .downloads-container {
		grid-column-start: 7;
		grid-column-end: 13;

		& .table {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			height: 100%;

			& .download {
				text-align: center;

				& a {
					display: block;
					width: 100%;
					height: 100%;

					&:hover {
						color: ${secondaryLight};

						& svg path {
							stroke: ${secondaryLight};
						}
					}
				}

				& div {
					width: 100%;
					height: 100%;

					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					justify-content: center;
					align-content: flex-end;
				}

				& span {
					width: 100%;
				}

				& span {
					margin: 10px 0 0 0;
				}
			}
		}
	}
`;

// Queries
const CUSTOMER_INFO = gql`
	query($customerAccessToken: String!) {
		customer(customerAccessToken: $customerAccessToken) {
			email
			firstName
			lastName
			tags
		}
	}
`;

const Products = ({ location, data }) => {
	const windowWidth = useContext(WindowWidthContext);
	const { customerAccessToken } = useContext(StoreContext);
	const tradeData = data.prismicAccounts.data;

	const [footerColor, setFooterColor] = useContext(FooterColor);
	useEffect(() => {
		setFooterColor(tertiary);
	}, []);

	const allProducts = data.allPrismicProduct.edges.map((product, index) => {
		const techSpecs = product.node.data.tech_specs
			.filter((file) => file.tech_specs_pdf !== null)
			.map((file, index) => (
				<div key={`single_tech_spec_${index}`} className="download">
					<a
						href={file.tech_specs_pdf.url}
						download
						target="_blank"
						rel="noreferrer"
					>
						<div>
							<TradeTechSpecs />
							<span>Tech Specs</span>
						</div>
					</a>
				</div>
			));

		const productImages = product.node.data.product_images
			.filter((images) => images.product_image_file !== null)
			.map((images, index) => (
				<div key={`single_product_images_${index}`} className="download">
					<a
						href={images.product_image_file.url}
						download
						target="_blank"
						rel="noreferrer"
					>
						<div>
							<TradeImages />
							<span>Images</span>
						</div>
					</a>
				</div>
			));

		const cadFiles = product.node.data.cad_files
			.filter((file) => file.cad_file !== null)
			.map((file, index) => (
				<div key={`single_cad_file_${index}`} className="download">
					<a href={file.cad_file.url} download target="_blank" rel="noreferrer">
						<div>
							<TradeCAD />
							<span>CAD File</span>
						</div>
					</a>
				</div>
			));

		return (
			<SingleProduct
				key={`single_trade_product_information_${product.node.id}`}
			>
				<div className="image-container">
					<Link to={`/products/${product.node.data.linked_product.handle}`}>
						<AspectRatioImageContainer image={null} padding={75}>
							{product.node.data.thumbnail.fluid !== null && (
								<img
									srcSet={product.node.data.thumbnail.fluid.srcSetWebp}
									src={product.node.data.thumbnail.fluid.srcWebp}
									alt={product.node.data.thumbnail.alt}
									loading={`lazy`}
								/>
							)}
						</AspectRatioImageContainer>
					</Link>
				</div>

				<div className="title-downloads-container">
					<div className="title-container">
						<h2 className="caps">{product.node.data.linked_product.title}</h2>

						<div
							className="product-text"
							dangerouslySetInnerHTML={{
								__html: product.node.data.linked_product.body_html,
							}}
						/>
					</div>

					<div className="downloads-container">
						<div className="table">
							{techSpecs}
							{productImages}
							{cadFiles}
						</div>
					</div>
				</div>
			</SingleProduct>
		);
	});

	return (
		<Layout>
			{customerAccessToken !== null && (
				<Query
					query={CUSTOMER_INFO}
					variables={{
						customerAccessToken: customerAccessToken.accessToken,
					}}
				>
					{({ loading, error, data }) => {
						if (loading) {
							return (
								<Page>
									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation position={`relative`} top={0} />
										)}
									/>
									<AccountContainer>
										<ContentContainer>
											<p className="margin-0">Fetching</p>
										</ContentContainer>
									</AccountContainer>
								</Page>
							);
						}
						if (error) {
							return (
								<Page>
									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation position={`relative`} top={0} />
										)}
									/>
									<AccountContainer>
										<ContentContainer>
											<p className="margin-0">
												Error loading page. Please try refreshing.
											</p>
										</ContentContainer>
									</AccountContainer>
								</Page>
							);
						}

						if (error) {
						}

						const isTrade =
							data.customer.tags !== undefined
								? data.customer.tags.includes("trade") || data.customer.tags.includes("Trade")
								: false;

						if (isTrade === false && typeof window !== "undefined") {
							return <>{navigate(`/account`)}</>;
						} else {
							return (
								<Page>
									<Helmet title={`Products | Christian Watson`} />

									<Media
										queries={{
											medium: "(min-width: 1025px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1025,
										}}
										render={() => (
											<AccountNavigation position={`relative`} top={0} />
										)}
									/>

									<Media
										queries={{
											medium: "(max-width: 1024px)",
										}}
										defaultMatches={{
											medium: windowWidth === 1024,
										}}
										render={() => (
											<MobileAccountNavigation position={`relative`} top={0} />
										)}
									/>

									<AccountContainer>
										<ContentContainer>
											<div className="section">
												<div
													className="product-text"
													dangerouslySetInnerHTML={{
														__html: tradeData.products_text.html,
													}}
												/>
											</div>
											<div className="section">{allProducts}</div>
										</ContentContainer>
									</AccountContainer>
								</Page>
							);
						}
					}}
				</Query>
			)}
		</Layout>
	);
};

export const query = graphql`
	{
		prismicAccounts {
			data {
				products_text {
					html
				}
			}
		}
		allPrismicProduct(sort: { fields: data___title___html, order: ASC }) {
			edges {
				node {
					uid
					id
					data {
						linked_product
						thumbnail {
							alt
							fluid {
								srcWebp
								srcSetWebp
							}
						}
						dimensions {
							dimensions_title
							dimensions_text
						}
						cad_files {
							cad_file {
								url
							}
						}
						tech_specs {
							tech_specs_pdf {
								url
							}
						}
						care_guides {
							care_guide {
								document {
									... on PrismicCareGuide {
										id
										url
										data {
											title {
												text
											}
										}
									}
								}
							}
						}
						product_images {
							product_image_file {
								url
							}
						}
					}
				}
			}
		}
	}
`;

export default Products;
