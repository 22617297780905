import React from "react";
import styled from "styled-components";

// Colors
import { secondaryDark } from "../utils/colors";

const Icon = styled.svg`
	position: relative;
`;

export const TradeImages = () => (
	<Icon
		width="70"
		height="70"
		viewBox="0 0 70 70"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M65 10H5V55H65V10Z" stroke={secondaryDark} strokeMiterlimit="10" />
		<path d="M10 15L60 50" stroke={secondaryDark} strokeMiterlimit="10" />
		<path d="M60 15L10 50" stroke={secondaryDark} strokeMiterlimit="10" />
	</Icon>
);

export const TradeTechSpecs = () => (
	<Icon
		width="70"
		height="70"
		viewBox="0 0 70 70"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M17 10H67V45" stroke={secondaryDark} strokeMiterlimit="10" />
		<path d="M7 10V55H67" stroke={secondaryDark} strokeMiterlimit="10" />
		<path d="M2 15L7 10L12 15" stroke={secondaryDark} strokeMiterlimit="10" />
		<path d="M62 50L67 55L62 60" stroke={secondaryDark} strokeMiterlimit="10" />
	</Icon>
);

export const TradeCAD = () => (
	<Icon
		width="70"
		height="70"
		viewBox="0 0 70 70"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5 20V50L35 65L65 50V20L35 5L5 20Z"
			stroke={secondaryDark}
			strokeMiterlimit="10"
		/>
		<path
			d="M35 60V35"
			stroke={secondaryDark}
			strokeWidth="0.9129"
			strokeMiterlimit="10"
		/>
		<path
			d="M60.125 22.4375L35 34.9995L9.875 22.4375"
			stroke={secondaryDark}
			strokeMiterlimit="10"
		/>
	</Icon>
);
